// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"
import "./src/styles/global.css"
import "./src/styles/layout.css"
import "./src/styles/main.less"
import "./static/libs/font-awesome/css/font-awesome.min.css"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"
import "./src/styles/highlight.css"
// @ts-ignore
window.AV = require("leancloud-storage")
// document.write(unescape("%3Cspan id='cnzz_stat_icon_1278634919'%3E%3C/span%3E%3Cscript src='https://s4.cnzz.com/z_stat.php%3Fid%3D1278634919' type='text/javascript'%3E%3C/script%3E"));
